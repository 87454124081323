<template>
  <div class="h-100 w-100">
    <!--begin::Dashboard-->
    <iframe src="https://app.powerbi.com/view?r=eyJrIjoiZjViMzk3MTctN2I2MS00ODUxLWIyYWYtNGJlNGEwNjUzMmVmIiwidCI6IjgwOTBhMTQ0LWE5Y2MtNDcxZC05MDI4LTJhNjBiYWI0OGNkMCIsImMiOjEwfQ%3D%3D" width="100%" height="100%"></iframe>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard-public",
  components: {
   //
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard Public" }]);
  },
  methods: {
    //
  }
};
</script>
